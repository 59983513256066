/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_import_classifications_from_excel_api_helper_import_classification__type__excel_post */
export interface BodyImportClassificationsFromExcelApiHelperImportClassificationTypeExcelPost {
  /**
   * Excel File
   * @format binary
   */
  excel_file: File;
}

/** Body_import_classifications_from_excel_api_helper_import_embeddings__classification_type__post */
export interface BodyImportClassificationsFromExcelApiHelperImportEmbeddingsClassificationTypePost {
  /**
   * Upload File
   * @format binary
   */
  upload_file: File;
}

/** Body_import_classifications_links_from_excel_api_helper_import_link__type1___type2__post */
export interface BodyImportClassificationsLinksFromExcelApiHelperImportLinkType1Type2Post {
  /**
   * Upload File
   * @format binary
   */
  upload_file: File;
}

/** Body_import_gpc_json_api_helper_import_gpc_json_post */
export interface BodyImportGpcJsonApiHelperImportGpcJsonPost {
  /**
   * Json File
   * @format binary
   */
  json_file: File;
}

/** Body_import_lineitem_links_from_excel_api_helper_import_line_item_link_ecoicop__post */
export interface BodyImportLineitemLinksFromExcelApiHelperImportLineItemLinkEcoicopPost {
  /**
   * Upload File
   * @format binary
   */
  upload_file: File;
}

/** Body_import_lineitems_from_excel_api_helper_import_line_items__post */
export interface BodyImportLineitemsFromExcelApiHelperImportLineItemsPost {
  /**
   * Upload File
   * @format binary
   */
  upload_file: File;
}

/** ClassificationCreateJson */
export interface ClassificationCreateJson {
  /** Title */
  title: string;
  /** Level */
  level: number;
  /** Description */
  description: string;
}

/** ClassificationJson */
export interface ClassificationJson {
  /** Title */
  title: string;
  /** Level */
  level: number;
  /** Description */
  description: string;
  /** Id */
  id: number;
  /** Type */
  type: string;
  /** Code */
  code: string;
  /** Parent Id */
  parent_id: number;
}

/** ClassificationPredictJson */
export interface ClassificationPredictJson {
  /** Similarity */
  similarity: number;
  /** Converted */
  converted: boolean;
  classification: ClassificationJson;
}

/** ClassificationResponseForGtin */
export interface ClassificationResponseForGtin {
  /** Gpc Title */
  gpc_title: string;
  /** Gpc Code */
  gpc_code: string;
  /** Gpc Description */
  gpc_description: string;
  /** Coicop Code */
  coicop_code: string;
  /** Coicop Title */
  coicop_title: string;
  /** Coicop Description */
  coicop_description: string;
}

/** ConversionJson */
export interface ConversionJson {
  /** Id */
  id: number;
  /** Source Parent Classification Id */
  source_parent_classification_id?: number;
  /** Source Classification Id */
  source_classification_id: number;
  /** Source Attribute Id */
  source_attribute_id?: number;
  /** Source Attribute Value Id */
  source_attribute_value_id?: number;
  /** Source Attribute Id2 */
  source_attribute_id2?: number;
  /** Source Attribute Value Id2 */
  source_attribute_value_id2?: number;
  /** Destination Classification Id */
  destination_classification_id: number;
  /** Confirmed */
  confirmed: boolean;
}

/** ConversionUpdateJson */
export interface ConversionUpdateJson {
  /** Source Parent Classification Id */
  source_parent_classification_id?: number;
  /** Source Classification Id */
  source_classification_id: number;
  /** Source Attribute Id */
  source_attribute_id?: number;
  /** Source Attribute Value Id */
  source_attribute_value_id?: number;
  /** Source Attribute Id2 */
  source_attribute_id2?: number;
  /** Source Attribute Value Id2 */
  source_attribute_value_id2?: number;
  /** Destination Classification Ids Csv */
  destination_classification_ids_csv: string;
  /** Confirmed */
  confirmed: boolean;
}

/** EmbeddingsJson */
export interface EmbeddingsJson {
  /** Gtin */
  gtin?: string;
  /** Text */
  text: string;
  /** Vectors */
  vectors: number[];
  /** Id */
  id: number;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** LineItemCreateJson */
export interface LineItemCreateJson {
  /** Gtin */
  gtin?: string;
  /** Title */
  title: string;
  /** Description */
  description: string;
}

/** LineItemJson */
export interface LineItemJson {
  /** Gtin */
  gtin?: string;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Id */
  id: number;
  /** Coicop Id */
  coicop_id: number;
  coicop: ClassificationJson;
  /** Gpc Id */
  gpc_id: number;
  gpc: ClassificationJson;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title MiniSuomi AI
 * @version 0.1
 *
 * Classification conversion with AI, implemented by using Azure Functions and Python.
 */
export class ClassificationsAPI<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Finds classifications
     *
     * @tags classifications
     * @name GetClassificationsApiClassificationsGet
     * @summary Get Classifications
     * @request GET:/api/classifications/
     */
    getClassificationsApiClassificationsGet: (
      query?: {
        /** Classification Type */
        classification_type?: string;
        /**
         * Page
         * @default 0
         */
        page?: number;
        /**
         * Per Page
         * @default 10
         */
        per_page?: number;
        /**
         * Order By Column
         * @default "id"
         */
        order_by_column?: string;
        /**
         * Desc
         * @default false
         */
        desc?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassificationJson[], HTTPValidationError>({
        path: `/api/classifications/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds classifications by id
     *
     * @tags classifications
     * @name GetClassificationsApiClassificationsIdGet
     * @summary Get Classifications
     * @request GET:/api/classifications/{id}
     */
    getClassificationsApiClassificationsIdGet: (
      id: number,
      query?: {
        /**
         * Load Parent
         * @default false
         */
        load_parent?: boolean;
        /**
         * Load Conversions
         * @default false
         */
        load_conversions?: boolean;
        /**
         * Load Attributes
         * @default false
         */
        load_attributes?: boolean;
        /**
         * Load Attribute Values
         * @default false
         */
        load_attribute_values?: boolean;
        /**
         * Load Embeddings
         * @default false
         */
        load_embeddings?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassificationJson, HTTPValidationError>({
        path: `/api/classifications/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds classification by type and code
     *
     * @tags classifications
     * @name GetClassificationApiClassificationsTypeCodeGet
     * @summary Get Classification
     * @request GET:/api/classifications/{type}/{code}
     */
    getClassificationApiClassificationsTypeCodeGet: (
      type: string,
      code: string,
      query?: {
        /**
         * Load Parent
         * @default false
         */
        load_parent?: boolean;
        /**
         * Load Children
         * @default false
         */
        load_children?: boolean;
        /**
         * Load Conversions
         * @default false
         */
        load_conversions?: boolean;
        /**
         * Load Attributes
         * @default false
         */
        load_attributes?: boolean;
        /**
         * Load Attribute Values
         * @default false
         */
        load_attribute_values?: boolean;
        /**
         * Load Embeddings
         * @default false
         */
        load_embeddings?: boolean;
        /**
         * Attribute Filter Csv
         * @default ""
         */
        attribute_filter_csv?: string;
        /**
         * Attribute Value Filter Csv
         * @default ""
         */
        attribute_value_filter_csv?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassificationJson, HTTPValidationError>({
        path: `/api/classifications/${type}/${code}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a new classification
     *
     * @tags classifications
     * @name CreateClassificationApiClassificationsTypeCodePost
     * @summary Create Classification
     * @request POST:/api/classifications/{type}/{code}
     * @secure
     */
    createClassificationApiClassificationsTypeCodePost: (
      type: string,
      code: string,
      data: ClassificationCreateJson,
      params: RequestParams = {},
    ) =>
      this.request<ClassificationJson, HTTPValidationError>({
        path: `/api/classifications/${type}/${code}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds classification by type and code and then deletes it.
     *
     * @tags classifications
     * @name DeleteClassificationApiClassificationsTypeCodeDelete
     * @summary Delete Classification
     * @request DELETE:/api/classifications/{type}/{code}
     * @secure
     */
    deleteClassificationApiClassificationsTypeCodeDelete: (type: string, code: string, params: RequestParams = {}) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/classifications/${type}/${code}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds classification by type and code and then returns all conversions
     *
     * @tags classifications
     * @name GetClassificationConversionsApiClassificationsSourceTypeSourceCodeConversionGet
     * @summary Get Classification Conversions
     * @request GET:/api/classifications/{source_type}/{source_code}/conversion
     */
    getClassificationConversionsApiClassificationsSourceTypeSourceCodeConversionGet: (
      sourceCode: string,
      sourceType: string,
      params: RequestParams = {},
    ) =>
      this.request<ClassificationJson[], HTTPValidationError>({
        path: `/api/classifications/${sourceType}/${sourceCode}/conversion`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Finds classification by type and code and returns all conversions which have a given type
     *
     * @tags classifications
     * @name GetClassificationConversionsByTypeApiClassificationsSourceTypeSourceCodeConversionDestinationTypeGet
     * @summary Get Classification Conversions By Type
     * @request GET:/api/classifications/{source_type}/{source_code}/conversion/{destination_type}
     */
    getClassificationConversionsByTypeApiClassificationsSourceTypeSourceCodeConversionDestinationTypeGet: (
      sourceType: string,
      sourceCode: string,
      destinationType: string,
      query?: {
        /**
         * Direct Conversion
         * @default true
         */
        direct_conversion?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassificationJson[], HTTPValidationError>({
        path: `/api/classifications/${sourceType}/${sourceCode}/conversion/${destinationType}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds classfication by type and code and adds new conversion for it.
     *
     * @tags classifications
     * @name AddClassificationConversionApiClassificationsSourceTypeSourceCodeConversionDestinationTypeDestinationCodePost
     * @summary Add Classification Conversion
     * @request POST:/api/classifications/{source_type}/{source_code}/conversion/{destination_type}/{destination_code}
     * @secure
     */
    addClassificationConversionApiClassificationsSourceTypeSourceCodeConversionDestinationTypeDestinationCodePost: (
      sourceType: string,
      sourceCode: string,
      destinationType: string,
      destinationCode: string,
      params: RequestParams = {},
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/classifications/${sourceType}/${sourceCode}/conversion/${destinationType}/${destinationCode}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds classfication by type and code and adds new conversion for it.
     *
     * @tags classifications
     * @name SetParentClassificationApiClassificationsSourceTypeSourceCodeSetParentIdParentIdPut
     * @summary Set Parent Classification
     * @request PUT:/api/classifications/{source_type}/{source_code}/set_parent_id/{parent_id}/
     * @secure
     */
    setParentClassificationApiClassificationsSourceTypeSourceCodeSetParentIdParentIdPut: (
      sourceType: string,
      sourceCode: string,
      parentId: number,
      params: RequestParams = {},
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/classifications/${sourceType}/${sourceCode}/set_parent_id/${parentId}/`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all conversions
     *
     * @tags conversions
     * @name GetConversionsApiConversionsGet
     * @summary Get Conversions
     * @request GET:/api/conversions/
     */
    getConversionsApiConversionsGet: (
      query?: {
        /** Classification Ids Csv */
        classification_ids_csv?: string;
        /** Source Type */
        source_type?: string;
        /** Destination Type */
        destination_type?: string;
        /** Page */
        page?: number;
        /** Per Page */
        per_page?: number;
        /**
         * Order By Column
         * @default "id"
         */
        order_by_column?: string;
        /**
         * Desc
         * @default false
         */
        desc?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConversionJson[], HTTPValidationError>({
        path: `/api/conversions/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates classification conversion, it removes old conversions and adds a new one if given
     *
     * @tags conversions
     * @name UpdateClassificationConversionApiConversionsPost
     * @summary Update Classification Conversion
     * @request POST:/api/conversions/
     * @secure
     */
    updateClassificationConversionApiConversionsPost: (data: ConversionUpdateJson, params: RequestParams = {}) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/conversions/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns items
     *
     * @tags Line items
     * @name GetLineItemsApiLineItemsGet
     * @summary Get Line Items
     * @request GET:/api/line_items/
     */
    getLineItemsApiLineItemsGet: (
      query?: {
        /**
         * Page
         * @default 0
         */
        page?: number;
        /**
         * Per Page
         * @default 10
         */
        per_page?: number;
        /**
         * Order By Column
         * @default "id"
         */
        order_by_column?: string;
        /**
         * Desc
         * @default false
         */
        desc?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<LineItemJson[], HTTPValidationError>({
        path: `/api/line_items/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a new line item
     *
     * @tags Line items
     * @name CreateLineItemApiLineItemsPost
     * @summary Create Line Item
     * @request POST:/api/line_items/
     * @secure
     */
    createLineItemApiLineItemsPost: (
      query: {
        /** Type */
        type: string;
        /** Code */
        code: string;
      },
      data: LineItemCreateJson,
      params: RequestParams = {},
    ) =>
      this.request<LineItemJson, HTTPValidationError>({
        path: `/api/line_items/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds line items by id
     *
     * @tags Line items
     * @name GetLineItemByIdApiLineItemsIdGet
     * @summary Get Line Item By Id
     * @request GET:/api/line_items/{id}
     */
    getLineItemByIdApiLineItemsIdGet: (id: string, params: RequestParams = {}) =>
      this.request<LineItemJson, HTTPValidationError>({
        path: `/api/line_items/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Delete line item by id.
     *
     * @tags Line items
     * @name DeleteLineItemApiLineItemsIdDelete
     * @summary Delete Line Item
     * @request DELETE:/api/line_items/{id}
     * @secure
     */
    deleteLineItemApiLineItemsIdDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/line_items/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Set coicop value for line item. Note that this information is used later to figure out other similar values... So make sure it is correct!
     *
     * @tags Line items
     * @name SetLineItemCoicopApiLineItemsIdCoicopCoicopCodePut
     * @summary Set Line Item Coicop
     * @request PUT:/api/line_items/{id}/coicop/{coicop_code}
     * @secure
     */
    setLineItemCoicopApiLineItemsIdCoicopCoicopCodePut: (id: number, coicopCode: string, params: RequestParams = {}) =>
      this.request<LineItemJson, HTTPValidationError>({
        path: `/api/line_items/${id}/coicop/${coicopCode}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Tries to figure out coicop value for line item
     *
     * @tags Line items
     * @name GetLineItemCoicopApiLineItemsIdCoicopGet
     * @summary Get Line Item Coicop
     * @request GET:/api/line_items/{id}/coicop/
     * @secure
     */
    getLineItemCoicopApiLineItemsIdCoicopGet: (id: number, params: RequestParams = {}) =>
      this.request<LineItemJson, HTTPValidationError>({
        path: `/api/line_items/${id}/coicop/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Make gpt query, you can use for gpt-3.5-turbo-0613 or gpt-4-0613 as model. For example: I have a object list of coicop classifications: [{"code": "05.1.1.1.4.2", "title": "Cabinets and chests of drawers"},{"code": "05.1.1.1.4","title": "Bookcase and other living room cabinets"},{"code":"05.3.1.2.1.2","title": "Dryers and cabinets"},{"code": "05.5.1.0.1","title": "Power tools and equipment"},{"code": "05.5.1.0.1.0","title": "Power tools and equipment"}]And I have a GPC classification object { "code": "10003674", "title": "Tool Cabinets", "description": "Includes any products that can be described/observed as a cabinet that is specifically designed to be used for the storage of tools. It may consist of shelves and/or drawers.", "excludes": "Excludes cabinets not specifically designed for tools."}. To which coicop classification following gpc classification falls into, just tell the code as an answer nothing else:
     *
     * @tags open ai
     * @name OpenaiQueryApiOpenAiQueryModelPost
     * @summary Openai Query
     * @request POST:/api/open_ai/query/{model}/
     * @secure
     */
    openaiQueryApiOpenAiQueryModelPost: (
      model: string,
      data: string,
      query?: {
        /** Last Question */
        last_question?: string;
        /** Last Answer */
        last_answer?: string;
        /**
         * Temperature
         * @default 0.9
         */
        temperature?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/api/open_ai/query/${model}/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Convert classification by using embeddings
     *
     * @tags open ai
     * @name ConvertClassificationListApiOpenAiConvertClassificationListSourceTypeDestinationTypeGet
     * @summary Convert Classification List
     * @request GET:/api/open_ai/convert-classification-list/{source_type}/{destination_type}
     */
    convertClassificationListApiOpenAiConvertClassificationListSourceTypeDestinationTypeGet: (
      sourceType: string,
      destinationType: string,
      query: {
        /** Classification Ids Csv */
        classification_ids_csv: string;
        /**
         * Max Count
         * @default 10
         */
        max_count?: number;
        /** Min Level */
        min_level?: number;
        /** Max Level */
        max_level?: number;
        /**
         * Model Name
         * @default "text-embedding-ada-002"
         */
        model_name?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, ClassificationPredictJson[]>, HTTPValidationError>({
        path: `/api/open_ai/convert-classification-list/${sourceType}/${destinationType}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Convert classification by using embeddings
     *
     * @tags open ai
     * @name ConvertClassificationApiOpenAiConvertClassificationSourceTypeSourceCodeDestinationTypeGet
     * @summary Convert Classification
     * @request GET:/api/open_ai/convert-classification/{source_type}/{source_code}/{destination_type}
     */
    convertClassificationApiOpenAiConvertClassificationSourceTypeSourceCodeDestinationTypeGet: (
      sourceType: string,
      sourceCode: string,
      destinationType: string,
      query?: {
        /**
         * Attributes Id Csv
         * @default ""
         */
        attributes_id_csv?: string;
        /**
         * Max Count
         * @default 10
         */
        max_count?: number;
        /** Min Level */
        min_level?: number;
        /** Max Level */
        max_level?: number;
        /**
         * Try Conversion
         * @default false
         */
        try_conversion?: boolean;
        /**
         * Model Name
         * @default "text-embedding-ada-002"
         */
        model_name?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassificationPredictJson[], HTTPValidationError>({
        path: `/api/open_ai/convert-classification/${sourceType}/${sourceCode}/${destinationType}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Pre query classification conversions
     *
     * @tags open ai
     * @name PreQueryGpcConversionsApiOpenAiPreQueryGpcConversionsPost
     * @summary Pre Query Gpc Conversions
     * @request POST:/api/open_ai/pre_query_gpc_conversions
     * @secure
     */
    preQueryGpcConversionsApiOpenAiPreQueryGpcConversionsPost: (
      data: string,
      query?: {
        /**
         * Overwrite
         * @default false
         */
        overwrite?: boolean;
        /**
         * Model
         * @default "gpt-4-0613"
         */
        model?: string;
        /**
         * Skip
         * @default 0
         */
        skip?: number;
        /**
         * Temperature
         * @default 0
         */
        temperature?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/api/open_ai/pre_query_gpc_conversions`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Predict classification
     *
     * @tags open ai
     * @name PredictClassificationApiOpenAiPredictClassificationPost
     * @summary Predict Classification
     * @request POST:/api/open_ai/predict-classification
     * @secure
     */
    predictClassificationApiOpenAiPredictClassificationPost: (
      data: string,
      query?: {
        /** Type */
        type?: string;
        /** Level */
        level?: number;
        /**
         * Max Count
         * @default 10
         */
        max_count?: number;
        /**
         * Try Conversion
         * @default false
         */
        try_conversion?: boolean;
        /**
         * Model Name
         * @default "text-embedding-ada-002"
         */
        model_name?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassificationPredictJson[], HTTPValidationError>({
        path: `/api/open_ai/predict-classification`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Generate fake content for testing. Give json object with empty values. For example: { "id": 0, "firstName": "", "lastName": "", "age": 0, "children": [{"id": 0, "firstName": "", "lastName": "", "age": 0, "children": []}] } or { "gtin": 0, "productName": "", "productDescription": "", "price": 0.0 } To get other values, use last value as example object...
     *
     * @tags open ai
     * @name GenerateFilledContentApiOpenAiGeneratePost
     * @summary Generate Filled Content
     * @request POST:/api/open_ai/generate
     * @secure
     */
    generateFilledContentApiOpenAiGeneratePost: (
      data: string,
      query?: {
        /**
         * Count
         * @default 5
         */
        count?: number;
        /**
         * Language
         * @default "finnish"
         */
        language?: any;
        /**
         * Extra Info
         * @default ""
         */
        extra_info?: string;
        /**
         * Last Answer
         * @default ""
         */
        last_answer?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any[], HTTPValidationError>({
        path: `/api/open_ai/generate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get embeddings
     *
     * @tags open ai
     * @name GetEmbeddingsApiOpenAiEmbeddingsGet
     * @summary Get Embeddings
     * @request GET:/api/open_ai/embeddings
     * @secure
     */
    getEmbeddingsApiOpenAiEmbeddingsGet: (
      query?: {
        /**
         * Page
         * @default 0
         */
        page?: number;
        /**
         * Per Page
         * @default 10
         */
        per_page?: number;
        /**
         * Order By Column
         * @default "id"
         */
        order_by_column?: string;
        /**
         * Desc
         * @default false
         */
        desc?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmbeddingsJson[], HTTPValidationError>({
        path: `/api/open_ai/embeddings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create embedding
     *
     * @tags open ai
     * @name GetEmbeddingsApiOpenAiEmbeddingsPost
     * @summary Get Embeddings
     * @request POST:/api/open_ai/embeddings
     * @secure
     */
    getEmbeddingsApiOpenAiEmbeddingsPost: (
      query: {
        /** Classification Id */
        classification_id: number;
        /**
         * Page
         * @default 0
         */
        page?: number;
        /**
         * Per Page
         * @default 10
         */
        per_page?: number;
        /**
         * Order By Column
         * @default "id"
         */
        order_by_column?: string;
        /**
         * Desc
         * @default false
         */
        desc?: boolean;
      },
      data: string,
      params: RequestParams = {},
    ) =>
      this.request<EmbeddingsJson[], HTTPValidationError>({
        path: `/api/open_ai/embeddings`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Use OpenAI to create empeddings for classifications (only creates if not exist yet).
     *
     * @tags open ai
     * @name UpdateClassificationEmbeddingsApiOpenAiGenerateEmbeddingsPost
     * @summary Update Classification Embeddings
     * @request POST:/api/open_ai/generate-embeddings
     * @secure
     */
    updateClassificationEmbeddingsApiOpenAiGenerateEmbeddingsPost: (
      data: string,
      query?: {
        /**
         * Page
         * @default 0
         */
        page?: number;
        /**
         * Per Page
         * @default 1000
         */
        per_page?: number;
        /**
         * Model Name
         * @default "text-embedding-ada-002"
         */
        model_name?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, HTTPValidationError>({
        path: `/api/open_ai/generate-embeddings`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Exports conversions in excel file
     *
     * @tags conversions
     * @name ExportClassificationConversionsApiHelperExportSourceTypeConversionsDestinationTypeGet
     * @summary Export Classification Conversions
     * @request GET:/api/helper/export/{source_type}/conversions/{destination_type}/
     */
    exportClassificationConversionsApiHelperExportSourceTypeConversionsDestinationTypeGet: (
      sourceType: string,
      destinationType: string,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/helper/export/${sourceType}/conversions/${destinationType}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Import gpc classifications from json file (https://gpc-browser.gs1.org/ download gpc as json). Note that this might take several minutes...
     *
     * @tags helper
     * @name ImportGpcJsonApiHelperImportGpcJsonPost
     * @summary Import Gpc Json
     * @request POST:/api/helper/import/gpc/json
     * @secure
     */
    importGpcJsonApiHelperImportGpcJsonPost: (
      data: BodyImportGpcJsonApiHelperImportGpcJsonPost,
      query?: {
        /**
         * Type
         * @default "gpc"
         */
        type?: string;
        /**
         * Overwrite
         * @default true
         */
        overwrite?: boolean;
        /**
         * Basic Update
         * @default true
         */
        basic_update?: boolean;
        /**
         * Update Bricks
         * @default false
         */
        update_bricks?: boolean;
        /**
         * Update Attributes
         * @default false
         */
        update_attributes?: boolean;
        /**
         * Update Attribute Attributes
         * @default false
         */
        update_attribute_attributes?: boolean;
        /** Start Segment */
        start_segment?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, HTTPValidationError>({
        path: `/api/helper/import/gpc/json`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Import classification values from excel file
     *
     * @tags helper
     * @name ImportClassificationsFromExcelApiHelperImportClassificationTypeExcelPost
     * @summary Import Classifications From Excel
     * @request POST:/api/helper/import/classification/{type}/excel
     * @secure
     */
    importClassificationsFromExcelApiHelperImportClassificationTypeExcelPost: (
      type: string,
      data: BodyImportClassificationsFromExcelApiHelperImportClassificationTypeExcelPost,
      query?: {
        /**
         * Code Column
         * @default "COICOP2018_code"
         */
        code_column?: string;
        /**
         * Level Column
         * @default "level"
         */
        level_column?: string;
        /**
         * Title Column
         * @default ""
         */
        title_column?: string;
        /** Description Column */
        description_column?: string;
        /** Extra Column */
        extra_column?: string;
        /**
         * Auto Level
         * @default false
         */
        auto_level?: boolean;
        /**
         * Auto Parent
         * @default true
         */
        auto_parent?: boolean;
        /**
         * Overwrite
         * @default true
         */
        overwrite?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, HTTPValidationError>({
        path: `/api/helper/import/classification/${type}/excel`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Import embeddings from csv/excel file.
     *
     * @tags helper
     * @name ImportClassificationsFromExcelApiHelperImportEmbeddingsClassificationTypePost
     * @summary Import Classifications From Excel
     * @request POST:/api/helper/import-embeddings/{classification_type}
     * @secure
     */
    importClassificationsFromExcelApiHelperImportEmbeddingsClassificationTypePost: (
      classificationType: string,
      query: {
        /** Code Column */
        code_column: string;
        /**
         * Title Embedding Column
         * @default "titleVectors"
         */
        title_embedding_column?: string;
        /**
         * Description Embedding Column
         * @default "descriptionVectors"
         */
        description_embedding_column?: string;
        /**
         * Extra Embedding Column
         * @default "excludeVectors"
         */
        extra_embedding_column?: string;
      },
      data: BodyImportClassificationsFromExcelApiHelperImportEmbeddingsClassificationTypePost,
      params: RequestParams = {},
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/helper/import-embeddings/${classificationType}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Import classification links from csv/excel file.
     *
     * @tags helper
     * @name ImportClassificationsLinksFromExcelApiHelperImportLinkType1Type2Post
     * @summary Import Classifications Links From Excel
     * @request POST:/api/helper/import-link/{type1}/{type2}
     * @secure
     */
    importClassificationsLinksFromExcelApiHelperImportLinkType1Type2Post: (
      type1: string,
      type2: string,
      query: {
        /** Type1 Code Column */
        type1_code_column: string;
        /** Type2 Code Column */
        type2_code_column: any;
        /**
         * Skip
         * @default 63000
         */
        skip?: number;
      },
      data: BodyImportClassificationsLinksFromExcelApiHelperImportLinkType1Type2Post,
      params: RequestParams = {},
    ) =>
      this.request<number, HTTPValidationError>({
        path: `/api/helper/import-link/${type1}/${type2}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Import lineitems from csv/excel file.
     *
     * @tags helper
     * @name ImportLineitemsFromExcelApiHelperImportLineItemsPost
     * @summary Import Lineitems From Excel
     * @request POST:/api/helper/import-line-items/
     * @secure
     */
    importLineitemsFromExcelApiHelperImportLineItemsPost: (
      data: BodyImportLineitemsFromExcelApiHelperImportLineItemsPost,
      query?: {
        /**
         * Gtin Column
         * @default "tuotenro"
         */
        gtin_column?: string;
        /**
         * Title Column
         * @default "tuotenimi"
         */
        title_column?: string;
        /**
         * Ecoicop7 Column
         * @default "coicop7"
         */
        ecoicop7_column?: string;
        /**
         * Description Column
         * @default "nimi7"
         */
        description_column?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<number, HTTPValidationError>({
        path: `/api/helper/import-line-items/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Import line item links from excel. Use level param to tell to which ecoicop level is used to make conversion link for given classification.
     *
     * @tags helper
     * @name ImportLineitemLinksFromExcelApiHelperImportLineItemLinkEcoicopPost
     * @summary Import Lineitem Links From Excel
     * @request POST:/api/helper/import-line-item-link/ecoicop/
     * @secure
     */
    importLineitemLinksFromExcelApiHelperImportLineItemLinkEcoicopPost: (
      data: BodyImportLineitemLinksFromExcelApiHelperImportLineItemLinkEcoicopPost,
      query?: {
        /**
         * Gtin Column
         * @default "GTIN"
         */
        gtin_column?: string;
        /**
         * Classification Type
         * @default "gpc"
         */
        classification_type?: string;
        /**
         * Level
         * @default 5
         */
        level?: number;
        /**
         * Classification Code Column
         * @default "GPC Brick"
         */
        classification_code_column?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/helper/import-line-item-link/ecoicop/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description clears database
     *
     * @tags database
     * @name DatabaseResetApiDatabaseDropPost
     * @summary Database Reset
     * @request POST:/api/database/drop
     * @secure
     */
    databaseResetApiDatabaseDropPost: (
      query?: {
        /**
         * Are You Sure
         * @default false
         */
        are_you_sure?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/database/drop`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description creates database schema
     *
     * @tags database
     * @name DatabaseCreateApiDatabaseCreatePost
     * @summary Database Create
     * @request POST:/api/database/create
     * @secure
     */
    databaseCreateApiDatabaseCreatePost: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/database/create`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description initalizes basic data
     *
     * @tags database
     * @name DatabaseInitApiDatabaseInitPost
     * @summary Database Init
     * @request POST:/api/database/init
     * @secure
     */
    databaseInitApiDatabaseInitPost: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/database/init`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get GPC by GTIN code
     *
     * @tags gs1
     * @name Gs1GetGpcApiGs1GtinGet
     * @summary Gs1 Get Gpc
     * @request GET:/api/gs1/{gtin}
     */
    gs1GetGpcApiGs1GtinGet: (gtin: string, params: RequestParams = {}) =>
      this.request<ClassificationResponseForGtin | string, HTTPValidationError>({
        path: `/api/gs1/${gtin}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}

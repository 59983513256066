import React, { ChangeEvent, useState } from 'react'
import { Button, Expander, ExpanderContent, ExpanderTitleButton, Heading, Paragraph, TextInput } from 'suomifi-ui-components';
import { ClassificationsAPI } from '../api/classifications-api/classifications-api';
// import Html5QrcodePlugin from '../components/QrCodePlugin';
// import { Html5QrcodeSupportedFormats } from 'html5-qrcode';
// import { BarcodeScanner } from 'react-barcode-scanner';

type Props = {}

const IntraView = (props: Props) => {
  const [gtin, setGtin] = useState<string>("");
  const [gpc, setGpc] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  
 
  const convertGtinToGpc = () => {
    const classificationsApi = new ClassificationsAPI({baseUrl:"https://classifications.minisuomi.net"}).api;
    setLoading(true);
    classificationsApi.gs1GetGpcApiGs1GtinGet(gtin).then(result => {
      
      if (typeof (result.data) === "string") {
        setResult(result.data);
      }
      else  {
        setGpc(`${result.data.gpc_code} ${result.data.gpc_title} ${result.data.gpc_description||""}`);
        setResult(`${result.data.coicop_code} ${result.data.coicop_title} ${result.data.coicop_description||""}`);
      }

    }).finally(() => {
      setLoading(false);
    })
  }

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    // handle decoded results here
    debugger;
  };

  return (
    <div>
      <Heading variant='h3'>Mini-TK Intra</Heading>

      <Paragraph>Tervetuloa intra-sivuille, täällä näet MiniTK:n sisäisiä toimintoja</Paragraph>

      {/* <BarcodeScanner /> */}

      <Expander defaultOpen={true}>
        <ExpanderTitleButton>
          Coicop-työkalu
        </ExpanderTitleButton>
        <ExpanderContent >
       
          <TextInput labelText="Anna GTIN koodi" type='text' value={gtin} onChange={(value: any) => setGtin(value)}></TextInput>
          <Button disabled={loading} label='Konvertoi COICOP-koodiksi' onClick={convertGtinToGpc}>Konvertoi COICOP-koodiksi</Button>
          <Paragraph>GPC: {gpc}</Paragraph>
          <Paragraph>COICOP: {result}</Paragraph>
        </ExpanderContent>
      
      </Expander>
    </div>
  )
}

export default IntraView;
import React from 'react'
import { Block, Text, Heading, LanguageMenu, LanguageMenuItem, Button, IconLogout, IconLogin, IconChartAnalytics } from 'suomifi-ui-components';
import Breadcrumbs from './Breadcrumbs';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../context/UserContext';

type Props = {
    
}

const languages = [
  { text: 'Suomeksi (FI)', lang: 'fi' },
  { text: 'På svenska (SV)', lang: 'sv' },
  { text: 'In English (EN)', lang: 'en' }
];

const Header = (props: Props) => {
  const { t, i18n } = useTranslation();
  const userContext = useUserContext();
  const name = userContext.name; // user name
  const isLoggedIn = userContext.isLoggedIn; 

  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  const selectedLanguage = languages.find(x => x.lang === i18n.resolvedLanguage) || languages[2]

  return (
    <Block variant='header'>
      <Box sx={{ height: '4px', backgroundColor: 'primary.main' }}></Box>
        <div style={{  margin: "auto", padding: "10px", maxWidth: "1200px", cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Link to="/" style={{ textDecoration: 'none', display: "flex", flexDirection: "row", alignItems: "center" }}>
            <IconChartAnalytics className='app-header-logo'></IconChartAnalytics>
            <Heading className='app-header-title' color={'brandBase'} variant='h1hero'>
              {t("appName")}
            </Heading>
          </Link>
        
        <div style={{flexGrow:1}}>
          <Heading className='mobile-display-none' style={{paddingLeft: "10px"}} variant="h5">{t('appDescription')}</Heading>
        </div>
        <LanguageMenu className='language-menu-button'
          buttonText={selectedLanguage.lang.toUpperCase()}
          aria-label={`Change language, selected language: ${selectedLanguage.text}`}
        >
          {languages.map((item) => (
            <LanguageMenuItem 
              onSelect={() => { i18n.changeLanguage(item.lang); }}
              lang={item.lang}
              key={item.lang}
              selected={i18n.resolvedLanguage === item.lang}
            >
              {item.text}
            </LanguageMenuItem>
          ))}
        </LanguageMenu>
        { !isLoginPage && 
          <>
          { isLoggedIn ?
            // <UserInfo name={name || ""} />
            <>
            <Text style={{margin: "0 10px"}}>{name}</Text>
            <Link to={"/logout"}><Button icon={<IconLogout/>} variant="default">{t('logout')}</Button></Link>
            </>
            :
            <Link to={"/login"}><Button icon={<IconLogin></IconLogin>} variant="default">{t('login')}</Button></Link>
            // location.pathname === "/logout" ?
            // <Link to={"/login"}><Button icon={<IconLogin></IconLogin>} variant="default">{t('login')}</Button></Link>
            // :
            // <Link to={"/login?redirect=" + encodeURI(location.pathname)}><Button icon={<IconLogin></IconLogin>} variant="default">{t('login')}</Button></Link>
          }
          </>
        }
        
      </div>
    
    { !isLoginPage && 
      <>
      <hr style={{marginTop: 0}}/>
      <Box sx={{ maxWidth: "1200px", paddingRight: "10px", paddingLeft: "10px", marginLeft: "auto", marginRight: "auto" }}>
        <Breadcrumbs key={"murupolku"} />
      </Box>
      <hr style={{marginBottom: 0}}/>
      </>
    }
  </Block>
  )
}

export default Header
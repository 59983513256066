import { Trans, useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Block, Button, Heading, Text, IconBuildingAdministrative,  IconLogin,  IconLogout,  LanguageMenu,  LanguageMenuItem,  Paragraph, IconArrowRight } from 'suomifi-ui-components'
import { Box } from '@mui/material'
import Breadcrumbs from '../components/Breadcrumbs'
import { useUserContext } from '../context/UserContext'
import Footer from '../components/Footer'
import Header from '../components/Header'




function Home() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  
  const userContext = useUserContext();
  const isLoggedIn = userContext.isLoggedIn; 
  
  return (
    <>
  <Header />
  <Block variant='main'>
    <Block variant="section">

      {location.pathname === "/" &&
        <>
          
            <Heading variant="h1">{t('homeView.welcome')}</Heading>
            <Paragraph>
              <Trans i18nKey="homeView.pageDescription">
                Tämä on demosivusto, joka on luotu <Link to={ t('ydUrl')}>yrityksen digitalous</Link> kokeiluja varten. 
              </Trans>
            </Paragraph>
            { isLoggedIn ?
              <Link to="/intra">
                <Button iconRight={<IconArrowRight></IconArrowRight>}>{t("homeView.gotoIntra")}</Button>
              </Link>
              : 
              <Paragraph>{t('homeView.pageInfo')}</Paragraph> 
            }
   
        </>
      }
      <Outlet></Outlet>
    </Block>
  </Block>
  <Footer></Footer>
  </>
  )
}

export default Home
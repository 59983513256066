import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        fallbackLng: "en",
        resources: {
            en: {
                translation: {
                    appName: "Mini-TK",  
                    appDescription: "Statistics MiniFinland",
                    footer: {
                        accessibilityStatement: "accessibility statement",
                        contactInformation: "contact information",
                        dataProtection: "data protection"
                    }, 
                    homeView: {
                        gotoIntra: 'Go to intra page',
                        pageDescription: "This is demo site for the <1>Real time economy</1> project's experiments.",
                        pageInfo: "Log in to test demo.",
                        welcome: "Welcome to Mini-TK",
                    },
                    loading: "Loading...",
                    login: "Log in",                   
                    loginView: {
                        email: "e-mail",
                        password: "password",
                        title: "Identification",
                        failed: "Login failed",
                    },
                    logout: "Log out",
                    logoutView: {
                        title: "You have been logged out",
                        redirect: "Continue to the front page",
                    },
                    route: {
                        home: "Frontpage",
                    },
                    sending: "Sending...",
                    ydUrl: "https://www.yrityksendigitalous.fi/en/",
                    
                }
            },
            fi: {
                translation: {
                    appName: "Mini-TK",
                    appDescription: "Tilastokeskus (demo)",
                    footer: {
                        accessibilityStatement: "saatavuusseloste",
                        contactInformation: "yhteystiedot",
                        dataProtection: "tietosuoja"
                    },
                    homeView: {
                        gotoIntra: 'Siirry intra-sivustolle',
                        pageDescription: 'Tämä on demosivusto, joka on luotu <1>yrityksen digitalous</1>-hankkeen kokeiluja varten.',
                        pageInfo: "Kirjaudu sisään demotaksesi todistuksien myöntämistä ja lähettämistä lompakkojen avulla.",
                        pageSlogan: "Valhe, emävalhe, tilasto",
                        welcome: "Tervetuloa Mini-Tilastokeskukseen"
                    },
                    loading: "Lataa...",
                    login: "Kirjaudu",
                    loginView: {
                        email: "sähköposti",
                        password: "salasana",
                        title: "Tunnistautuminen",
                        failed: "Kirjautuminen epäonnistui",
                    },
                    logout: "Kirjaudu ulos",
                    logoutView: {
                        title: "Olet uloskirjautunut",
                        redirect: "Siirry etusivulle",
                    },
                    route: {
                        home: "Etusivu",
                    },
                    sending: "Lähettää...",
                    ydUrl: "https://www.yrityksendigitalous.fi/",
                }
            },
            sv: {
                translation: {
                    appName: "Mini-TK",
                    appDescription: "Statistikcentralen MiniFinland", 
                    footer: {
                        accessibilityStatement: "tillgänglighetsförklaring",
                        contactInformation: "kontaktinformation",
                        dataProtection: "dataskydd"
                    },    
                    homeView: {
                        gotoIntra: "Gå till intrasidan",
                        pageDescription: "Detta är en demowebbplats för att testa experiment med <1>Realtidsekonomi</1>.",
                        pageInfo: "Logga in för att testa demos.",
                        welcome: "Välkommen till Mini-TK",
                    },
                    loading: "",// "Laddar...",
                    login: "Logga in",
                    loginView: {
                        email: "e-post",
                        password: "lösenord",
                        title: "Identifiering",
                        failed: "Inloggningen misslyckades",
                    },
                    logout: "Logga ut",
                    logoutView: {
                        title: "Du är utloggad",
                        redirect: "Fortsätt till startsidan",
                    },
                    route: {
                        home: "Hemsida"
                    },
                    sending: "Sändning...",
                    ydUrl: "https://www.yrityksendigitalous.fi/sv/",
                }
            }
        }
    })